<!-- 加入我们 -->
<template>
<div class="positionList">
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <!--顶部文字-->
    <div class="wCenter textPar">
        <div v-if="config.c1&&config.c1.isShow">
            <p class="text" v-for="(item, index) in config.c1.descr.split('\n')" :key="index">{{item}}</p>
        </div>
    </div>
    <!--顶部文字-->
    <!--职位列表-->
    <div class="positionBody" v-if="config.c2&&config.c2.isShow&&data&&data.length>0">
        <div class="wCenter">
            <p class="title">目前热招职位</p>
            <Table stripe :columns="columns" :data="data" class="pc"></Table>
            <div class="mobileTab" v-for="(item,index) in data" :key="index" @click="goDetail(item.id)">
                <p class="title">职位:<Icon type="md-arrow-round-forward"  style="float:right;font-size: 0.6rem;"/></p>
                <p class="text">{{item.position}}</p>
                <p class="title">发布时间</p>
                <p class="text">{{item.pushTime}}</p>
                <p class="title">工作地点</p>
                <p class="text">{{item.region}}</p>
            </div>
            <!-- <div class="tabFoot">
                <Page :current="searchForm.pageNumber" :total="total" :page-size="searchForm.pageSize" @on-change="changePage" @on-page-size-change="changePageSize" :page-size-opts="[10,20,50]" size="small" show-total show-elevator show-sizer></Page>
            </div> -->
        </div>
    </div>
    <div class="wCenter" v-else>
        <p class="text mb50">信息维护中。。。</p>
    </div>
    <!--职位列表-->
</div>
</template>

<script>
import util from "@/libs/util.js";
import {
    getPageConfigByCode,
    getJobRecruitList
} from '@/api/api'
import TopImg from '@/components/topImg'
export default {
    name: '',
    components: {
        TopImg,
    },
    data() {
        return {
            config: {},
            searchForm: {
                pageNumber: 1,
                pageSize: 10,
            },
            total: 0,
            columns: [{
                    title: '职位',
                    key: 'position',
                },
                {
                    title: '工作地点',
                    key: 'region',
                },
                {
                    title: '发布时间',
                    key: 'pushTime',
                    // width: 200,
                },
                {
                    title: '详情',
                    key: "action",
                    width: 100,
                    render: (h, params) => {
                        let node = [];
                        node.push(h(
                            "Icon", {
                                props: {
                                    type: "ios-arrow-forward",
                                    size: "20"
                                },
                                style: {
                                    cursor: "pointer"
                                },
                                on: {
                                    click: () => {
                                        this.goDetail(params.row.id);
                                    }
                                }
                            },

                        ));
                        return h("div", node);
                    }
                }
            ],
            data: [],
        }
    },
    computed: {},
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getPageConfigByCode()
        },
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'positionList/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                    let c2 = this.config.c2
                    if (c2 && c2.isShow) {
                        this.getJobRecruitList();
                    }
                }
            })
        },
        changePage(v) {
            this.searchForm.pageNumber = v;
            this.getJobRecruitList();
        },
        changePageSize(v) {
            this.searchForm.pageSize = v;
            this.getJobRecruitList();
        },
        // 获取职位列表
        getJobRecruitList() {
            getJobRecruitList(this.searchForm).then(res => {
                if (res.success) {
                    this.data = res.result;
                    // this.total = res.result.total;
                }
            })
        },
        goDetail(e) {
            util.pageChange(this.$router, `/positionDetail`, {
                id: e
            }, '_blank');

        }

    },
}
</script>

<style lang="scss" scoped>
.positionList {
    .pc {
        display: block;
    }

    .mobileTab {
        display: none;
    }

    @media (max-width: 767px) {
        .pc {
            display: none;
        }

        .mobileTab {
            display: block;
        }
    }

    // .positionMain {
    //     background: #eeeeee;
    //     padding: 0.4rem 0.5rem;
    //     margin-top: 0.7rem;
    //     margin-bottom: 0.67rem;

    //     .title {
    //         color: #333333;
    //         font-size: 0.24rem;
    //         margin-bottom: 0.3rem;
    //     }

    //     .ivu-form {
    //         display: flex;
    //         flex-wrap: wrap;
    //     }

    //     .product {
    //         width: 10.7rem;
    //     }

    //     .submit {
    //         width: 1rem;
    //         margin-left: 0.3rem;

    //         @media (max-width: 767px) {
    //             width: 50%;
    //             margin-left: 25%;
    //         }

    //         .ivu-btn-primary {
    //             width: 1rem;
    //             height: 0.5rem;
    //             background-color: #333333;
    //             border-color: #333333;

    //             @media (max-width: 767px) {
    //                 width: 100%;
    //                 height: 0.5rem * 1.5;
    //             }
    //         }
    //     }
    // }
    .textPar{
        margin-top: .3rem;
    }
    .text {
        color: #333333;
        font-size: 0.24rem;
        line-height: 2;
		@media (max-width: 767px) {
		   font-size: 0.38rem;
		}
    }

    .positionBody {
        margin-top: 0.3rem;
        margin-bottom: 0.7rem;

        .title {
            color: #ff1932;
            font-size: 0.24rem;
            margin-bottom: 0.2rem;
			@media (max-width: 767px) {
			   font-size: 0.4rem;
			}
        }
    }

    .tabFoot {
        text-align: center;
        border-top: 0;
    }

    .mobileTab {
        border: 1px solid #eeeeee;
        border-radius: 0.2rem;
        padding: 0.2rem;
        margin-bottom: 0.2rem;

        .title {
            color: #ff1932;
            font-weight: bold;
            font-size: 0.24rem;
            margin-bottom: 0;
			@media (max-width: 767px) {
			   font-size: 0.38rem;
			}
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            margin-top: 0;
            margin-bottom: 0.2rem;
			@media (max-width: 767px) {
			   font-size: 0.38rem;
			}

        }
    }
}
</style>
<style lang="scss">
.positionList {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .ivu-table td {
        height: 0.5rem;
    }

    .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
    .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
        background-color: #ffffff;
    }

    .ivu-table-header thead tr th {
        padding: 0.15rem 0;
        background-color: #ff1932;
        color: #ffffff;
    }

    .ivu-table-body table {
        border: 0.01rem solid #eeeeee;
    }
}
</style>
